import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { useUser } from '../../../contexts/userContext';
import { useColumns } from '../../../contexts/columnsContext';
import { useCard } from '../../../contexts/cardContext';
import './style.css';

// API URL for IBGE
const apiUrlIbge = 'https://servicodados.ibge.gov.br/api/v1/localidades';

function UpdateCard({ idCard, cardData }) {
  const { user, listAllUsers, empresa } = useUser();
  const { columnsUser, columns, listaEtiquetas } = useColumns();
  const { addHistoricoCardContext, setOpenCloseUpdateCard, cards, setCards, setPreviewSearchCards, setListNotifications } = useCard();

  const [statusCard, setStatusCard] = useState('');
  const [statusDateCard, setStatusDateCard] = useState(null);
  const [documentNumber, setDocumentNumber] = useState('');
  const [secondDocumentoNumber, setSecondDocumentNumber] = useState('');
  const [pedidoNumber, setPedidoNumber] = useState('');

  const [entityId, setEntityId] = useState();
  const [name, setName] = useState('');
  const [saleValue, setSaleValue] = useState();
  const [costValue, setCostValue] = useState();
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [fone, setFone] = useState('');
  const [columnId, setColumnId] = useState();
  const [error, setError] = useState('');
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);
  const [count, setCount] = useState(0);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentIdColumn, setCurrentIdColumn] = useState();
  const [origemCard, setOrigemCard] = useState('');
  const [produtoCard, setProdutoCard] = useState('');

  const [listOrigens, setListOrigens] = useState([]);
  const [listProdutos, setListProdutos] = useState([]);

  const [idEtiqueta, setIdEtiqueta] = useState(0);

  const [cpf, setCpf] = useState('');  // Novo estado para CPF

  const [cpfError, setCpfError] = useState('');  // Novo estado para erros de CPF

  const [endereco, setEndereco] = useState(''); // Novo estado para endereço

  const [participanteId, setParticipanteId] = useState(''); // Novo estado para armazenar o participante
  const [participantesList, setParticipantesList] = useState([]); // Lista de participantes do tipo parceiro


  const [contractSigned, setContractSigned] = useState(false);
  const [contractSignedAt, setContractSignedAt] = useState(null);
  const [contractSignedByUser, setContractSignedByUser] = useState(null);


  const [currentEntity, setCurrentEntity] = useState();
  const [responsavel, setResponsavel] = useState('');





  useEffect(() => {
    setCount(count + 1);
    if (count > 1) {
      setIsUpdatingCard(true);
    }
  }, [statusCard, documentNumber, secondDocumentoNumber, pedidoNumber, entityId, name, saleValue, costValue, email, state, city, fone, columnId, origemCard]);


  const formatToBrazilianCurrency = (value) => {
    if (value === undefined || value === null) return '';
    return value.toString().replace('.', ',');
  };

  useEffect(() => {
    if (cardData) {


      setDocumentNumber(cardData.document_number || '');
      // setSaleValue(cardData.sale_value);
      // setCostValue(cardData.cost_value);

      setSaleValue(formatToBrazilianCurrency(cardData.sale_value)); // Formatar valor de venda
      setCostValue(formatToBrazilianCurrency(cardData.cost_value));

      setName(cardData.name);
      setEmail(cardData.email);
      setState(cardData.state);
      setCity(cardData.city);
      setFone(cardData.fone);
      setColumnId(cardData.column_id);
      setEntityId(cardData.entity_id);
      setStatusCard(cardData.status);
      setOrigemCard(cardData.origem ? cardData.origem : 'Sem Origem');
      setProdutoCard(cardData.produto);
      setStatusDateCard(cardData.status_date ? new Date(cardData.status_date).toISOString().split('T')[0] : ''); // Formate a data aqui
      setSecondDocumentNumber(cardData.second_document_number);
      setPedidoNumber(cardData.pedido_number);
      setIdEtiqueta(cardData.etiqueta_id || 0); // Garantir que idEtiqueta seja configurado corretamente
      setCpf(cardData.cpf || ''); // Garantir que idEtiqueta seja configurado corretamente
      setEndereco(cardData.endereco || '');
      setParticipanteId(cardData.participante_id || '');

      setContractSigned(cardData.contract_signed);
      setContractSignedAt(cardData.contract_signed_at);
      setContractSignedByUser(cardData.signed_by_user_id);
      setResponsavel(cardData.responsavel);

      //


      setCurrentEntity(cardData.entity_id)
      validateDocument(cardData.cpf || ''); //

    }
    buscarOrigens()
    buscarProdutos()
  }, [cardData]);

  useEffect(() => {
    axios.get(`${apiUrlIbge}/estados?orderBy=nome`).then(response => {
      const stateOptions = response.data.map(state => ({
        sigla: state.sigla,
        nome: state.nome,
      }));
      setStates(stateOptions);
    });
  }, []);

  useEffect(() => {
    if (state) {
      axios.get(`${apiUrlIbge}/estados/${state}/municipios?orderBy=nome`).then(response => {
        const cityOptions = response.data.map(city => ({
          id: city.id,
          nome: city.nome,
        }));
        setCities(cityOptions);

        // Seleciona a cidade atual se estiver definida
        if (cardData && cardData.city) {
          const selectedCity = response.data.find(city => city.nome === cardData.city);
          if (selectedCity) {
            setCity(selectedCity.id.toString());

          }
        }
      });
    } else {
      setCities([]);
    }
  }, [state, cardData]);

  const getNameColumnCard = (idColumn) => {
    if (!columnsUser) {
      return 'Dados ainda estão carregando...';
    }
    const nameColumn = columnsUser.find((item) => item.id === idColumn);
    return nameColumn ? nameColumn.name : 'Nome não encontrado';
  };




  function getUserName(userId) {
    // Verifica se o userId é diferente do usuário atual
    if (userId === user.id) {
      return user.username; // Retorna o nome do usuário atual diretamente
    }

    //console.log(userId)
    const userFromList = listAllUsers.find(user => user.id === userId);
    return userFromList ? userFromList.username : 'Unknown';
  }

  const handleUpdateCard = async (e) => {
    e.preventDefault();
    setIsUpdatingCard(true);
    setError('');


    // Verificação do campo de valor de custo
    if (costValue === '' || costValue === null || costValue === undefined || isNaN(convertToDatabaseFormat(costValue))) {
      setError('O campo de valor de custo deve ser preenchido e deve ser um valor válido.');
      setIsUpdatingCard(false);
      return;
    }



    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }

    const selectedState = states.find((st) => st.sigla === state);
    const selectedCity = cities.find((ct) => ct.id === parseInt(city));



    const cardData = {
      id: idCard,
      name,
      state: selectedState ? selectedState.sigla : '',
      city: selectedCity ? selectedCity.nome : '',
      fone,
      email,
      column_id: columnId,
      entity_id: entityId,
      empresa_id: user.empresa_id,
      document_number: documentNumber ? documentNumber : '',
      cost_value: costValue,
      sale_value: saleValue,
      cost_value: convertToDatabaseFormat(costValue), // Converter valor de custo
      sale_value: convertToDatabaseFormat(saleValue), // Converter valor de venda

      status: statusCard ? statusCard : '',
      origem: origemCard ? origemCard : 'Sem Origem',
      produto: produtoCard ? produtoCard : 'Não informado',
      status_date: statusDateCard ? statusDateCard : null, // Envie sempre o status_date no formato correto
      second_document_number: secondDocumentoNumber ? secondDocumentoNumber : '',
      pedido_number: pedidoNumber ? pedidoNumber : '',
      etiqueta_id: idEtiqueta ? idEtiqueta : null,
      cpf: cpf || '',
      endereco: endereco || '',
      participante_id: participanteId || null,
      responsavel: responsavel || '',
    };

    try {
      const response = await axios.post(`${apiUrl}/card/update`, cardData);
      const updatedCardData = response.data[0];

      setCards(prevCards => prevCards.map(card => card.card_id === idCard ? { ...card, ...updatedCardData } : card));
      setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === idCard ? { ...card, ...updatedCardData } : card));
      setListNotifications(prevCards => prevCards.map(card => card.card_id === idCard ? { ...card, ...updatedCardData } : card));

      setOpenCloseUpdateCard(false);
      setIsUpdatingCard(false);

      if (columnId !== currentIdColumn) {
        addHistoricoCardContext(`Coluna alterada para ${getNameColumnCard(columnId)}`, idCard, user.id);
      }


      if (currentEntity !== entityId) {
        addHistoricoCardContext(`Entidade alterada para ${getUserName(parseInt(entityId))}`, idCard, user.id);
      }


      console.log('currentEntity', currentEntity)


    } catch (error) {
      setIsUpdatingCard(false);
      setError('Erro ao atualizar o Card.');
    }
  };


  useEffect(() => {
    if (cardData) {
      setCurrentIdColumn(cardData.column_id);
    }
  }, [cardData]);



  const arquivarCard = async (id, event) => {
    event.stopPropagation();

    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }


    const arquivadosColumn = columns.find(column => column.name === empresa.coluna_arquivado);
    if (!arquivadosColumn) {
      console.error("Coluna não encontrada");
      //return;
    }

    let columnId;

    if (arquivadosColumn) {
      columnId = arquivadosColumn.id;
    } else {
      columnId = cardData.column_id;
    }

    try {
      const response = await axios.post(`${apiUrl}/card/update-arquivados`, {
        id,
        status: 'Arquivado',
        columnId: columnId,
      });
      setCards(prevCards => prevCards.map(card => card.card_id === id ? { ...card, ...response.data } : card));
      setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === id ? { ...card, ...response.data } : card));
      setListNotifications(prevCards => prevCards.map(card => card.card_id === id ? { ...card, ...response.data } : card));
      addHistoricoCardContext(`Card Arquivado!`, cardData.card_id, user.id);
      setOpenCloseUpdateCard(false);
    } catch (error) {
      console.error('Erro ao arquivar o cartão:', error);
    }
  };



  const buscarOrigens = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card/origens/${user.empresa_id}`);
      setListOrigens(response.data);
    } catch (error) {
      console.error('Erro ao buscar origens:', error);
    }
  };

  const buscarProdutos = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card/produtos/${user.empresa_id}`);
      setListProdutos(response.data);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    }
  };


  const sortedEtiquetas = [...listaEtiquetas].sort((a, b) => a.order - b.order);


  const convertToDatabaseFormat = (value) => {
    if (!value) return 0;
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
  };




  const validateDocument = (cpf) => {
    if (!cpf) {
      setCpfError(''); // Limpar erro se o campo estiver vazio
      return;
    }

    const cleanCpf = cpf.replace(/[^\d]+/g, '');

    if (cleanCpf.length === 11) {
      // CPF
      if (validateCPF(cleanCpf)) {
        setCpfError(''); // CPF válido
      } else {
        setCpfError('CPF inválido');
      }
    } else if (cleanCpf.length === 14) {
      // CNPJ
      if (validateCNPJ(cleanCpf)) {
        setCpfError(''); // CNPJ válido
      } else {
        setCpfError('CNPJ inválido');
      }
    } else {
      setCpfError('Documento deve ter 11 (CPF) ou 14 dígitos (CNPJ)');
    }
  };

  const validateCPF = (cpf) => {
    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  };

  const validateCNPJ = (cnpj) => {
    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  };





  const handleSaveClient = async () => {
    // Verificar se todos os campos obrigatórios estão preenchidos
    if (!name || !email || !fone || !state || !city || !cpf || cpfError !== '' || !endereco) {
      alert('Por favor, preencha todos os campos obrigatórios e garanta que o CPF/CNPJ seja válido.');
      return;
    }

    try {
      // Chamada para verificar se o cliente já existe pelo CPF/CNPJ
      //const response = await axios.post(`${apiUrl}/card/participantes/check-and-save`, {
      const response = await axios.post(`${apiUrl}/card/participantes/create`, {

        name,
        email,
        telefone: fone,
        endereco,
        tipo: 'Cliente',  // Tipo padrão para o cliente
        state,
        city,
        empresa_id: user.empresa_id,
        entity_id: entityId,
        cpf: cpf,
      });

      alert('Cliente salvo com sucesso!');

    } catch (error) {
      console.error('Erro ao salvar o cliente:', error);
      alert('Erro ao salvar o cliente. Verifique o console para mais detalhes.');
    }
  };






  const handleSearchClient = async () => {
    if (!cpf) {
      alert('Por favor, insira o CPF/CNPJ para buscar o cliente.');
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/card/participantes/search`, {
        params: { cpf },
      });

      if (response.data.success) {
        const cliente = response.data.data;
        setName(cliente.name);
        setEmail(cliente.email);
        setFone(cliente.telefone);
        setEndereco(cliente.endereco);
        setState(cliente.state);


        // Buscar as cidades novamente e selecionar a cidade correta
        axios.get(`${apiUrlIbge}/estados/${cliente.state}/municipios?orderBy=nome`).then(response => {
          const cityOptions = response.data.map(city => ({
            id: city.id,
            nome: city.nome,
          }));
          setCities(cityOptions);

          // Selecionar a cidade correta com base no nome da cidade do cliente
          const selectedCity = response.data.find(city => city.nome === cliente.city);
          if (selectedCity) {
            setCity(selectedCity.id.toString());
          }
        });
      } else {
        alert('Cliente não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar o cliente:', error);
      alert('Erro ao buscar o cliente. Verifique o console para mais detalhes.');
    }
  };





  useEffect(() => {
    const fetchParticipantes = async () => {
      try {
        // Ajuste o endpoint para corresponder ao caminho correto no seu backend
        const response = await axios.get(`${apiUrl}/card/participantes/parceiros/${user.id}`);
        setParticipantesList(response.data);
      } catch (error) {
        console.error('Erro ao buscar participantes:', error);
      }
    };

    if (user.id) {
      fetchParticipantes();
    }
  }, [user]);






  const handleSignContract = async () => {
    const confirmAction = window.confirm(
      contractSigned
        ? "Deseja desmarcar este contrato como assinado?"
        : "Deseja marcar este contrato como assinado?"
    );

    if (confirmAction) {
      try {
        const response = await axios.post(`${apiUrl}/card/update-contract-status`, {
          cardId: idCard,
          contractSigned: !contractSigned, // Inverte o estado da assinatura
          userId: user.id // Define quem assinou o contrato
        });

        const { contract_signed, contract_signed_at, signed_by_user_id } = response.data;

        console.log(contract_signed, contract_signed_at, signed_by_user_id)

        if (true) {
          setContractSigned(contract_signed); // Atualiza o estado
          setContractSignedAt(new Date(contract_signed_at)); // Atualiza a data de assinatura
          setContractSignedByUser(signed_by_user_id); // Define quem assinou o contrato
          alert("Status do contrato atualizado com sucesso!");
        } else {
          alert("Erro ao atualizar o status do contrato.");
        }
      } catch (error) {
        console.error("Erro ao atualizar o status do contrato:", error);
        alert("Erro ao atualizar o status do contrato.");
      }
    }
  };



  const getUsernameById = (entityId) => {

    if (!user) {
      return 'Erro: Dados necessários não estão disponíveis.';
    }

    const entidade = listAllUsers.find(afilhado => afilhado.id === entityId);
    if (entidade && entidade.username) {
      return entidade.username;
    }

    if (entityId === user.id) {
      return user.username;
    }

    return 'id não encontrado';
  };



  return (
    <div className='update-card-modal'>
      <div className='update-card-container-update'>
        <div className='header-update-card-container'>
          <label>Oportunidade</label>
        </div>
        <div className="update-card-form-container">


          <label htmlFor="cpf-cnpj" className='update-card-label-input'>Assinatura de Contrato:</label>


          {/* Botão de Assinar Contrato */}
          <div className='sign-contract-container'>


            <button
              className={`sign-contract-button ${contractSigned ? 'true' : 'false'}`}
              onClick={handleSignContract}
            >
              {contractSigned ? 'Assinado' : 'Não Assinado'}
            </button>

            {contractSignedByUser && contractSignedAt && (
              <div className="signed-info">
                Atualizado por: {getUsernameById(contractSignedByUser)} em {new Date(contractSignedAt).toLocaleDateString('pt-BR')}
              </div>
            )}



          </div>

          <form className="update-card-form" onSubmit={handleUpdateCard}>














            <label htmlFor="cpf-cnpj" className='update-card-label-input'>CPF ou CNPJ do Cliente:</label>

            <div className='cpf-btn-container'>


              <input
                id="cpf-cnpj"
                className="update-card-input"
                type="text"
                value={cpf}
                onChange={(e) => {
                  setCpf(e.target.value);
                  validateDocument(e.target.value);
                }}
              />
              <button
                className="save-client-button"
                type="button" // Adicione isso
                onClick={handleSaveClient}
                disabled={cpfError !== '' || cpf === ''}  // Desabilita o botão se o CPF/CNPJ não for válido ou estiver vazio
              >
                Salvar Cliente
              </button>

              <button
                className="search-client-button"
                type="button" // Adicione isso
                onClick={handleSearchClient}
              >
                Buscar Cliente
              </button>



            </div>

            {cpfError && <div className="update-card-error-message">{cpfError}</div>}

            <label htmlFor="username" className='update-card-label-input'>Nome do Cliente:</label>
            <input id="username" className="update-card-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />


            <label htmlFor="endereco" className='update-card-label-input'>Endereço:</label>
            <input id="endereco" className="update-card-input" type="text" value={endereco} onChange={(e) => setEndereco(e.target.value)} />






            <label htmlFor="city-state" className='update-card-label-input'>Estado:</label>
            <div className='select-cidade-estado-container'>
              <select id="state" className="select-estado-cidade" value={state} onChange={(e) => setState(e.target.value)}>
                <option value="">Selecione o estado</option>
                {states.map(state => (
                  <option key={state.sigla} value={state.sigla}>{state.nome}</option>
                ))}
              </select>
            </div>


            <label htmlFor="city-state" className='update-card-label-input'>Cidade:</label>

            <div className='select-cidade-estado-container'>

              <select id="city" className="select-estado-cidade" value={city} onChange={(e) => setCity(e.target.value)} disabled={!state}>
                <option value="">Selecione a cidade</option>
                {cities.map(city => (
                  <option key={city.id} value={city.id}>{city.nome}</option>
                ))}
              </select>
            </div>

            <label htmlFor="fone" className='update-card-label-input'>Fone:</label>
            <input id="fone" className="update-card-input" type="text" value={fone} onChange={(e) => setFone(e.target.value)} />

            <label htmlFor="email" className='update-card-label-input'>Email:</label>
            <input id="email" className="update-card-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

            <label htmlFor="documentNumber" className='update-card-label-input'>PrefWeb:</label>
            <input id="documentNumber" className="update-card-input" type="text" value={documentNumber} onChange={(e) => setDocumentNumber(e.target.value)} />

            <label htmlFor="documentNumber" className='update-card-label-input'>PrefSuite:</label>
            <input id="documentNumber" className="update-card-input" type="text" value={secondDocumentoNumber} onChange={(e) => setSecondDocumentNumber(e.target.value)} />

            <label htmlFor="documentNumber" className='update-card-label-input'>Faktory:</label>
            <input id="documentNumber" className="update-card-input" type="text" value={pedidoNumber} onChange={(e) => setPedidoNumber(e.target.value)} />

            <label htmlFor="costValue" className='update-card-label-input'>Valor de Custo:</label>
            <input id="costValue" className="update-card-input" type="text" value={costValue} onChange={(e) => setCostValue(e.target.value)} />

            <label htmlFor="saleValue" className='update-card-label-input'>Valor de Venda:</label>
            <input id="saleValue" className="update-card-input" type="text" value={saleValue} onChange={(e) => setSaleValue(e.target.value)} />




            {/* <label htmlFor="city-state" className='update-card-label-input'>Etiqueta:</label>
            <div className='select-cidade-estado-container'>
              <select id="etiqueta" className="select-estado-cidade" value={idEtiqueta} onChange={(e) => setIdEtiqueta(e.target.value)}  >
                <option value="">Selecione uma Etiqueta</option>
                {sortedEtiquetas.map(etiqueta => (
                  <option key={etiqueta.id} value={etiqueta.id}>{etiqueta.description}</option>
                ))}
              </select>
            </div> */}


            <label htmlFor="responsavel" className='update-card-label-input'>Representante Responsável:</label>
            <input
              id="responsavel"
              className="update-card-input"
              type="text"
              value={responsavel}
              onChange={(e) => setResponsavel(e.target.value)}
            />



            <label htmlFor="city-state" className='update-card-label-input'>Origem:</label>
            <div className='select-cidade-estado-container'>
              <select id="origem" className="select-estado-cidade" value={origemCard} onChange={(e) => setOrigemCard(e.target.value)}  >
                <option value="">Selecione a origem</option>
                {listOrigens.map(origem => (
                  <option key={origem.id} value={origem.name}>{origem.name}</option>
                ))}
              </select>
            </div>

            <label htmlFor="city-state" className='update-card-label-input'>Produto:</label>

            <div className='select-cidade-estado-container'>
              <select id="produto" className="select-estado-cidade" value={produtoCard} onChange={(e) => setProdutoCard(e.target.value)}  >
                <option value="">Selecione o produto</option>
                {listProdutos.map(produto => (
                  <option key={produto.id} value={produto.name}>{produto.name}</option>
                ))}
              </select>
            </div>



            <label htmlFor="participanteId" className='update-card-label-input'>Selecionar Participante:</label>
            <div className='select-cidade-estado-container'>

              <select
                id="participanteId"
                className="select-estado-cidade"
                value={participanteId}
                onChange={(e) => setParticipanteId(e.target.value)}
              >
                <option value="">Selecione um Participante</option>
                {participantesList.map(participante => (
                  <option key={participante.id} value={participante.id}>{participante.name}</option>
                ))}
              </select>
            </div>



            <label htmlFor="city-state" className='update-card-label-input'>Entidade:</label>


            <div className='select-cidade-estado-container'>
              <select id="produto" className="select-estado-cidade" value={entityId} onChange={(e) => setEntityId(e.target.value)}  >
                <option value="">Alterar Entidade</option>
                {listAllUsers.map(produto => (
                  <option key={produto.id} value={produto.id}>{produto.username}</option>
                ))}
              </select>
            </div>

            <div className='module-esquadrias-column'>
              <label htmlFor="previsaoVistoriaPos" className='modulo-esquadrias-label-input'>Alterar a Data de Status manualmente:</label>
              <input id="previsaoVistoriaPos" className="modulo-esquadrias-input" type="date" name="previsaoVistoriaPos" value={statusDateCard} onChange={(e) => setStatusDateCard(e.target.value)} />
            </div>


            <label htmlFor="columnId" className='update-card-label-input'>Column ID: {columnId}</label>
            <label htmlFor="idCard" className='update-card-label-input'>Card ID: {idCard}</label>
          </form>
        </div>
        {error && <div className="update-card-error-message">{error}</div>}

        <div className='update-card-footer'>
          <button className="update-card-close-button" onClick={(event) => arquivarCard(cardData.card_id, event)}>Arquivar</button>
          <button className="update-card-close-button" onClick={() => setOpenCloseUpdateCard(false)}>Cancelar</button>
          <button style={{ backgroundColor: !isUpdatingCard ? '' : 'dodgerblue' }} type="submit" className="update-card-button" onClick={handleUpdateCard} disabled={!isUpdatingCard}>Atualizar Card</button>
        </div>
      </div>
    </div>
  );
}

export default UpdateCard;
