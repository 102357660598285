



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { apiUrl } from '../../config/apiConfig';
// import { useUser } from '../../contexts/userContext';
// import { FaBell } from 'react-icons/fa';
// import sinoSound from '../../assets/bell.mp3';
// import logoDefault from '../../assets/logo-suite-flow.ico'; // Logo SyncsCRM
// import party from 'party-js';
// import './style.css';

// function TVSlider() {
//   const { user, listAllUsers } = useUser();
//   const [dailySales, setDailySales] = useState([]);
//   const [newSales, setNewSales] = useState([]);
//   const [highlightedSales, setHighlightedSales] = useState([]);
//   const [showBell, setShowBell] = useState(false);
//   const [isConfettiActive, setIsConfettiActive] = useState(false);
//   const [userInteracted, setUserInteracted] = useState(false);
//   const [saleNotified, setSaleNotified] = useState(false);
//   const [totalSales, setTotalSales] = useState(0);
//   const [topSales, setTopSales] = useState([]);

//   useEffect(() => {
//     // Detectar qualquer interação do usuário
//     const handleUserInteraction = () => {
//       setUserInteracted(true);
//     };

//     document.addEventListener('click', handleUserInteraction);
//     document.addEventListener('keydown', handleUserInteraction);

//     return () => {
//       document.removeEventListener('click', handleUserInteraction);
//       document.removeEventListener('keydown', handleUserInteraction);
//     };
//   }, []);

//   useEffect(() => {
//     if (!user) return;

//     fetchDailySales();

//     const intervalId = setInterval(fetchNewSales, 15000);
//     return () => clearInterval(intervalId);
//   }, [user]);

//   const fetchDailySales = async () => {
//     try {
//       const url = `${apiUrl}/card/sold-today/${user.empresa_id}`;
//       const response = await axios.get(url);
//       setDailySales(response.data);

//       const total = response.data.reduce((acc, sale) => acc + parseFloat(sale.cost_value || 0), 0);
//       setTotalSales(total);

//       // Ordenar por valor de vendas e pegar os 3 maiores valores
//       const top = response.data.sort((a, b) => b.cost_value - a.cost_value).slice(0, 3);
//       setTopSales(top);
//     } catch (error) {
//       console.error('Erro ao buscar vendas do dia:', error);
//     }
//   };

//   const fetchNewSales = async () => {
//     try {
//       const url = `${apiUrl}/card/sold-today/${user.empresa_id}`;
//       const response = await axios.get(url);
//       setNewSales(response.data);
//     } catch (error) {
//       console.error('Erro ao buscar novas vendas:', error);
//     }
//   };

//   useEffect(() => {
//     if (newSales.length > dailySales.length && !saleNotified) {
//       const newlySold = newSales.filter(
//         (sale) => !dailySales.some((previousSale) => sale.card_id === previousSale.card_id)
//       );

//       if (newlySold.length > 0) {
//         setHighlightedSales(newlySold);
//         setShowBell(true);
//         setSaleNotified(true);

//         // Toca o som apenas se o usuário já interagiu
//         if (userInteracted) {
//           const audio = new Audio(sinoSound);
//           audio.play().catch((error) => {
//             console.error('Erro ao reproduzir o som:', error);
//           });
//         }

//         if (!isConfettiActive) {
//           startConfetti();
//         }

//         setTimeout(() => {
//           setShowBell(false);
//           setDailySales(newSales);
//           setHighlightedSales([]);
//           setSaleNotified(false);
//         }, 15000);
//       }
//     }
//   }, [newSales, dailySales, userInteracted, saleNotified]);

//   const fireConfetti = () => {
//     party.confetti(document.body, {
//       count: party.variation.range(70, 150),
//       spread: 60,
//       size: party.variation.range(2.5, 2.8),
//     });
//   };

//   const startConfetti = () => {
//     setIsConfettiActive(true);
//     let count = 0;
//     const confettiInterval = setInterval(() => {
//       fireConfetti();
//       count++;
//       if (count >= 6) {
//         clearInterval(confettiInterval);
//         setIsConfettiActive(false);
//       }
//     }, 2200);
//   };

//   const getUsernameById = (id) => {
//     const user = listAllUsers.find((user) => user.id === id);
//     return user ? user.username : 'Usuário não encontrado';
//   };

//   const getAvatarById = (id) => {
//     const user = listAllUsers.find((user) => user.id === id);
//     return user && user.avatar
//       ? user.avatar.includes('syncs-avatar')
//         ? require(`../../assets/avatares/${user.avatar}`)
//         : user.avatar
//       : logoDefault;
//   };


//   const getUserStateById = (id) => {
//     const user = listAllUsers.find((user) => user.id === id);
//     return user ? user.state : 'Estado não encontrado';
//   };


//   const [time, setTime] = useState(new Date());

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTime(new Date());
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);


//   return (
//     <div className="tv-screen">
//       {/* Painel de Vendas Diárias */}

//       <div className='container-row-tv-infos'>


//         <div className='container-infos-dia'>
//           <div className="sales-summary">
//             <h1>Vendas Totais do Dia</h1>
//             <h2 className="total-sales">
//               {totalSales.toLocaleString('pt-BR', {
//                 style: 'currency',
//                 currency: 'BRL',
//               })}
//             </h2>
//           </div>

//           <div className="sales-summary-list-list">

//             <h3>Top Vendedores</h3>
//             <div className="top-sales-list">
//               {topSales.map((sale) => (
//                 <div key={sale.card_id} className="top-sale-item">
//                   <span>{getUsernameById(sale.entity_id)}</span>
//                   <span className="top-sale-value">
//                     {parseFloat(sale.cost_value || 0).toLocaleString('pt-BR', {
//                       style: 'currency',
//                       currency: 'BRL',
//                     })}
//                   </span>
//                 </div>
//               ))}
//             </div>
//           </div>

//         </div>

//         {/* Lista de vendas diárias */}
//         <div className="sales-list-container">
//           <div className="sales-list">
//             {dailySales.map((sale) => (
//               <div key={sale.card_id} className="sale-card">
//                 <img className="sale-avatar" src={getAvatarById(sale.entity_id)} alt="Logo" />






//                 <div>
//                   <div className="sale-details">
//                     <span className="sale-username">{getUsernameById(sale.entity_id)}</span>
//                   </div>

//                   <div className="sale-details">
//                     <span className="sale-state">{getUserStateById(sale.entity_id)}</span>
//                   </div>



//                   <div className="sale-details">
//                     <span className="sale-value">
//                       {parseFloat(sale.cost_value || 0).toLocaleString('pt-BR', {
//                         style: 'currency',
//                         currency: 'BRL',
//                       })}
//                     </span>
//                   </div>


//                   <div className="progress-bar-container">
//                   <div
//                     className="progress-bar"
//                     style={{ width: `${(parseFloat(sale.cost_value || 0) / totalSales) * 100}%` }}
//                   ></div>
//                 </div>



//                 </div>


//               </div>




//             ))}
//           </div>
//         </div>

//         {showBell && (
//           <div className="notification-popup-container">
//             <div className="notification-popup">
//               {highlightedSales.map((sale) => (
//                 <div key={sale.card_id} className="highlighted-sale">
//                   <img className="highlighted-avatar" src={getAvatarById(sale.entity_id)} alt="Logo" />
//                   <div className="highlighted-details">
//                     <h3>{getUsernameById(sale.entity_id)}</h3>
//                     <h2 className="highlighted-value">
//                       {parseFloat(sale.cost_value || 0).toLocaleString('pt-BR', {
//                         style: 'currency',
//                         currency: 'BRL',
//                       })}
//                     </h2>
//                   </div>
//                   <FaBell className="highlighted-bell-icon" />
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}



//       </div>

//       {/* Logo SyncsCRM */}
//       <div className="logo-syncs">
//         <img src={logoDefault} alt="Logo SyncsCRM" />
//         <h2>SyncsCRM</h2>

//         <div className="clock">
//           {time.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
//         </div>

//       </div>



//     </div>
//   );
// }

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import { useUser } from '../../contexts/userContext';
import { FaBell } from 'react-icons/fa';
import sinoSound from '../../assets/bell.mp3';
import logoDefault from '../../assets/logo-suite-flow.ico'; // Logo SyncsCRM
import party from 'party-js';
import { Line } from 'react-chartjs-2'; // Importando o componente de gráfico Line
import { Chart, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'; // Registrando os elementos do gráfico
import './style.css';

// Registrando os elementos necessários para o Line chart
Chart.register(LineElement, CategoryScale, LinearScale, PointElement);

function TVSlider() {
  const { user, listAllUsers } = useUser();
  const [dailySales, setDailySales] = useState([]);
  const [newSales, setNewSales] = useState([]);
  const [highlightedSales, setHighlightedSales] = useState([]);
  const [showBell, setShowBell] = useState(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [saleNotified, setSaleNotified] = useState(false);
  const [totalSales, setTotalSales] = useState(0);
  const [topSales, setTopSales] = useState([]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
    };

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    fetchDailySales();

    const intervalId = setInterval(fetchNewSales, 15000);
    return () => clearInterval(intervalId);
  }, [user]);

  // const fetchDailySales = async () => {
  //   try {
  //     const url = `${apiUrl}/card/sold-today/${user.empresa_id}`;
  //     const response = await axios.get(url);
  //     setDailySales(response.data);

  //     const total = response.data.reduce((acc, sale) => acc + parseFloat(sale.cost_value || 0), 0);
  //     setTotalSales(total);

  //     // Ordenar por valor de vendas e pegar os 3 maiores valores
  //     const top = response.data.sort((a, b) => b.cost_value - a.cost_value).slice(0, 3);
  //     setTopSales(top);
  //   } catch (error) {
  //     console.error('Erro ao buscar vendas do dia:', error);
  //   }
  // };


  const fetchDailySales = async () => {
    try {
      const url = `${apiUrl}/card/sold-today/${user.empresa_id}`;
      const response = await axios.get(url);
      setDailySales(response.data);
  
      const total = response.data.reduce((acc, sale) => acc + parseFloat(sale.cost_value || 0), 0);
      setTotalSales(total);
  
      // Ordenar por valor de vendas e exibir toda a lista
      const top = response.data.sort((a, b) => b.cost_value - a.cost_value);
      setTopSales(top); // Exibindo a lista completa ordenada
    } catch (error) {
      console.error('Erro ao buscar vendas do dia:', error);
    }
  };
  

  const fetchNewSales = async () => {
    try {
      const url = `${apiUrl}/card/sold-today/${user.empresa_id}`;
      const response = await axios.get(url);
      setNewSales(response.data);
    } catch (error) {
      console.error('Erro ao buscar novas vendas:', error);
    }
  };

  useEffect(() => {
    if (newSales.length > dailySales.length && !saleNotified) {
      const newlySold = newSales.filter(
        (sale) => !dailySales.some((previousSale) => sale.card_id === previousSale.card_id)
      );

      if (newlySold.length > 0) {
        setHighlightedSales(newlySold);
        setShowBell(true);
        setSaleNotified(true);

        // Toca o som apenas se o usuário já interagiu
        if (userInteracted) {
          const audio = new Audio(sinoSound);
          audio.play().catch((error) => {
            console.error('Erro ao reproduzir o som:', error);
          });
        }

        if (!isConfettiActive) {
          startConfetti();
        }

        setTimeout(() => {
          setShowBell(false);
          setDailySales(newSales);
          setHighlightedSales([]);
          setSaleNotified(false);
        }, 15000);
      }
    }
  }, [newSales, dailySales, userInteracted, saleNotified]);

  const fireConfetti = () => {
    party.confetti(document.body, {
      count: party.variation.range(70, 150),
      spread: 60,
      size: party.variation.range(2.5, 2.8),
    });
  };

  const startConfetti = () => {
    setIsConfettiActive(true);
    let count = 0;
    const confettiInterval = setInterval(() => {
      fireConfetti();
      count++;
      if (count >= 6) {
        clearInterval(confettiInterval);
        setIsConfettiActive(false);
      }
    }, 2300);
  };

  const getUsernameById = (id) => {
    const user = listAllUsers.find((user) => user.id === id);
    return user ? user.username : 'Usuário não encontrado';
  };

  const getAvatarById = (id) => {
    const user = listAllUsers.find((user) => user.id === id);
    return user && user.avatar
      ? user.avatar.includes('syncs-avatar')
        ? require(`../../assets/avatares/${user.avatar}`)
        : user.avatar
      : logoDefault;
  };

  const getUserStateById = (id) => {
    const user = listAllUsers.find((user) => user.id === id);
    return user ? user.state : 'Estado não encontrado';
  };

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Função para gerar os dados do gráfico de linha para cada venda
  const getLineDataForSale = (saleValue) => {
    const timeLabels = ['Início', 'Agora'];
    return {
      labels: timeLabels,
      datasets: [
        {
          label: 'Valor de Venda',
          data: [0, parseFloat(saleValue || 0)], // A primeira posição representa o início, a segunda o valor atual
          borderColor: '#36a2eb',
          fill: false,
          tension: 0.1,
        },
      ],
    };
  };


  const getLastSale = () => {
    if (dailySales.length === 0) return null;
    return dailySales[dailySales.length - 1];
  };


  return (
    <div className="tv-screen">
      {/* Painel de Vendas Diárias */}
      <div className='container-row-tv-infos'>


        <div className='container-infos-dia'>
          <div className="sales-summary">
            <h1>Vendas Totais do Dia</h1>
            <h2 className="total-sales">
              {totalSales.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </h2>
          </div>

          <div className="sales-summary-list-list">

            <h3>Vendas</h3>
            <div className="top-sales-list">
              {topSales.map((sale) => (
                <div key={sale.card_id} className="top-sale-item">
                  <span>{getUsernameById(sale.entity_id)}</span>
                  <span className="top-sale-value">
                    {parseFloat(sale.cost_value || 0).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </div>
              ))}
            </div>
          </div>



          <div className="last-sale">
            
            {getLastSale() ? (
              <div className="last-sale-item">
                <img
                  className="last-sale-avatar"
                  src={getAvatarById(getLastSale().entity_id)}
                  alt="Avatar"
                />
                <div className="last-sale-info">
                  <h3 style={{color: 'white', fontSize: '16px', marginBottom: '10px'}}>Última Venda</h3>
                  <span style={{color: 'white', fontSize: '16px', marginBottom: '10px'}}>{getUsernameById(getLastSale().entity_id)}</span>
                  <span className="last-sale-value">
                    {parseFloat(getLastSale().cost_value || 0).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </div>
              </div>
            ) : (
              <span>Nenhuma venda registrada ainda.</span>
            )}
          </div>



        </div>

        {/* Lista de vendas diárias */}
        <div className="sales-list-container">
          <div className="sales-list">
            {dailySales.map((sale) => (
              <div key={sale.card_id} className="sale-card">
                <img style={{ borderRadius: '10px' }} className="sale-avatar" src={getAvatarById(sale.entity_id)} alt="Logo" />
                <div>

                  {/* Adicionando o gráfico Line dentro de cada card */}
                  {/* <div className="sales-chart-card">
                    <Line data={getLineDataForSale(sale.cost_value)} />
                  </div> */}


                  <div className="sale-details">
                    <span className="sale-username">{getUsernameById(sale.entity_id)}</span>
                  </div>

                  <div className="sale-details">
                    <span className="sale-state">{getUserStateById(sale.entity_id)}</span>
                  </div>

                  <div className="sale-details">
                    <span className="sale-value">
                      {parseFloat(sale.cost_value || 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </span>
                  </div>



                  <div className="progress-bar-container">
                    <div
                      className="progress-bar"
                      style={{ width: `${(parseFloat(sale.cost_value || 0) / totalSales) * 100}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {showBell && (
          <div className="notification-popup-container">
            <div className="notification-popup">
              {highlightedSales.map((sale) => (
                <div key={sale.card_id} className="highlighted-sale">
                  <img className="highlighted-avatar" src={getAvatarById(sale.entity_id)} alt="Logo" />
                  <div className="highlighted-details">
                    <h3>{getUsernameById(sale.entity_id)}</h3>
                    <h2 className="highlighted-value">
                      {parseFloat(sale.cost_value || 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </h2>
                  </div>
                  <FaBell className="highlighted-bell-icon" />
                </div>
              ))}
            </div>
          </div>
        )}



      </div>

      {/* Logo SyncsCRM */}
      <div className="logo-syncs">
        <img src={logoDefault} alt="Logo SyncsCRM" />
        <h2>SyncsCRM - www.syncscrm.com.br</h2>

        <div className="clock">
          {time.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
        </div>

      </div>



    </div>
  );
}

export default TVSlider;
