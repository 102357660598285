import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import { useUser } from '../../../contexts/userContext';
import { useColumns } from '../../../contexts/columnsContext';
import { useCard } from '../../../contexts/cardContext';

import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';

import './style.css';
import Column from '../../Column';
import Card from '../../Card';

import { DragDropContext } from 'react-beautiful-dnd';
import PreviewCard from '../../PreviewCard';
import GoogleCalendario from '../../GoogleCalendario';
import Select from '../../Select';
import BemVindo from '../../BemVindo';

import { MdOutlineFilterList, MdGroups, MdOutlineSearch, MdOutlineUpdate } from "react-icons/md";
import { MdPerson, MdPhone, MdAttachMoney, MdDescription, MdLocalShipping, MdLocationCity, MdLabel, MdPublic } from 'react-icons/md';


import ImportExcel from '../../ImportExcel';
import ImportExcelSuiteFlow from '../../ImportExcelSuiteFlow';
import { RiFileExcel2Line } from "react-icons/ri";

import { BsCalendarDate } from "react-icons/bs";

import logoDefault from '../../../assets/logo-suite-flow.ico'
import Vendas from '../../Vendas';

import ExcelJS from 'exceljs';



// // Função para pedir permissão de notificações
// function askNotificationPermission() {
//   //console.log('Solicitando permissão para notificações...');
//   return Notification.requestPermission().then((permissionResult) => {
//     //console.log('Resultado da permissão:', permissionResult);
//     if (permissionResult !== 'granted') {
//       throw new Error('Permissão para notificações não concedida');
//     } else {
//       //console.log('Permissão para notificações concedida.');
//     }
//   }).catch(error => {
//     console.error('Erro ao solicitar permissão para notificações:', error);
//   });
// }


function askNotificationPermission() {
  // Verifica se o dispositivo é iOS e se está no Safari, pois o suporte é limitado
  const isIos = /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (isIos) {
    console.warn('Notificações push não são suportadas em dispositivos iOS via Service Workers.');
    return Promise.reject(new Error('iOS não suporta notificações push.'));
  }

  // Solicita permissão de notificações
  return Notification.requestPermission().then(permissionResult => {
    if (permissionResult !== 'granted') {
      throw new Error('Permissão para notificações não concedida');
    } else {
      console.log('Permissão para notificações concedida.');
    }
  }).catch(error => {
    console.error('Erro ao solicitar permissão para notificações:', error);
  });
}




// Converter chave VAPID para Uint8Array
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}



function HomePage() {

  const { user, openCloseImportExcelEntidades, openCloseImportExcelSuiteFlow, afilhadosList, getAccessLevel, userAvatar, empresa } = useUser();
  const { selectedEtiqueta, setSelectedEtiqueta, listaEtiquetas, setListaEtiquetas, showSharedCards, setShowSharedCards, editableColumns, columnsUser, setLoadingResult, setLoadingModal, selectedAfilhados, setSelectedAfilhados, dataInicial, setDataInicial, dataFinal, setDataFinal, orderBy, setOrderBy, isAscending, setIsAscending, localSearchTerm, handleLocalSearch, setores, selectedSetores, setSelectedSetores, handleSetorChange, cardsFiltradosLocalmente, setCardsFiltradosLocalmente } = useColumns();
  const { fetchCards, addHistoricoCardContext, cards, setCards, previewSearchCards, setPreviewSearchCards, searchTerm, setSearchTerm, setCurrentCardData, setOpenCloseUpdateCard } = useCard();

  const [openCloseSearchModal, setOpenCloseSearchModal] = useState(false);
  const [openCloseSelectAfilhadosModal, setOpenCloseSelectAfilhadosModal] = useState(false);
  const [openCloseSelectDateModal, setOpenCloseSelectDateModal] = useState(false);
  const [openCloseFilterModal, setOpenCloseFilterModal] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);


  const [searchType, setSearchType] = useState('name'); // Estado para armazenar o tipo de pesquisa selecionado



  const vapidPublicKey = 'BB7PgWv8ONF467L4KP_BslDx3yXteeIN5irXGyvf9DuCv_gnoEojq-gOHQkuU3JV1a1ti0oLUe34OX_D9YlWZj4'; // Substitua pela sua chave VAPID real

  useEffect(() => {
    // Solicitar permissão de notificações ao carregar o componente
    askNotificationPermission()
      .then(() => {
        if ('serviceWorker' in navigator) {
          //console.log("Suporte a Service Worker detectado. Registrando...");

          navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
              //console.log('Service Worker registrado com sucesso:', registration);

              // Verifica se o Push Manager está disponível
              return registration.pushManager.getSubscription()
                .then(subscription => {
                  if (subscription === null) {
                    // console.log('Nenhuma inscrição existente, inscrevendo usuário...');
                    return registration.pushManager.subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
                    });
                  } else {
                    // console.log('Usuário já está inscrito no Push Manager:', subscription);
                    return subscription;
                  }
                })
                .then(subscription => {
                  // Enviar a assinatura para o backend
                  sendSubscriptionToBackend(subscription, user.id);
                });
            })
            .catch(error => {
              console.error('Falha ao registrar o Service Worker:', error);
            });
        }
      })
      .catch(error => {
        console.error('Erro ao solicitar permissão de notificações ou registrar service worker:', error);
      });
  }, []);

  // Função para enviar a inscrição para o backend
  const sendSubscriptionToBackend = async (subscription, userId) => {
    try {
      //console.log('Enviando inscrição ao backend...', subscription);
      const response = await axios.post(`${apiUrl}/notifications/subscribe`, {
        userId, // Envia o userId junto com a subscription
        subscription,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // console.log('Inscrição salva no servidor:', response.data);
    } catch (error) {
      console.error('Falha ao enviar inscrição para o servidor:', error);
    }
  };














  const fetchCardsByName = async () => {
    setLoadingSearch(true);
    if (searchTerm.trim()) {
      try {
        const response = await axios.get(`${apiUrl}/card/search`, {
          params: {
            searchType, // Passa o tipo de pesquisa selecionado
            searchTerm, // Termo de busca
            entityId: user.id,
            empresaId: user.empresa_id,
          }
        });
        setPreviewSearchCards(response.data);
        setLoadingSearch(false);
      } catch (error) {
        console.error('Failed to fetch cards:', error);
        setLoadingSearch(false);
      }
    } else {
      setPreviewSearchCards([]);
    }
  };





  const handleSearchClick = () => {
    if (searchTerm.length < 3) return;
    setPreviewSearchCards([]);
    fetchCardsByName();
  };

  function getCardData(card) {
    setCurrentCardData(card);
    setOpenCloseUpdateCard(true);
    setSearchTerm('');
  }

  const getNameColumnCard = (idColumn) => {
    if (!columnsUser) {
      return 'Dados ainda estão carregando...';
    }

    const nameColumn = columnsUser.find((item) => item.id === idColumn);
    return nameColumn ? nameColumn.name : 'Nome não encontrado';
  };


  const handleOnDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    const startCards = [...cards];

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return; // Não faz nada se não há destino ou se o card foi largado na mesma posição
    }

    const { cardData, block_column } = JSON.parse(draggableId);
    const currentCardId = cardData.card_id;
    const currentColumnId = parseInt(source.droppableId, 10);
    const newColumnId = parseInt(destination.droppableId, 10);

    // // Nova verificação adicionada
    // if (!cardData.city || !cardData.state) {
    //   alert('Não é possível mover o card. Cidade e/ou Estado não preenchidos.');
    //   return;
    // }


    // Verificação para Cidade, Estado e Origem
    if (!cardData.city || !cardData.state || !cardData.origem || cardData.origem == 'Sem Origem') {
      const missingField = !cardData.city ? 'Campo cidade' : !cardData.state ? 'Campo estado' : ' Campo origem';
      alert(`Não é possível mover o card. ${missingField} não preenchido.`);
      return;
    }



    if (!getAccessLevel('coluna')) {
      const confirmDelete = window.alert('Não autorizado pelo Administrador!');
      return
    }

    if (block_column) {
      alert('Você não pode mover este card.');
      return;
    }

    // Verifica se a coluna de destino está na lista de colunas editáveis
    const editableColumnIds = editableColumns.map(column => column.columnId);

    // Verifica se a coluna atual do card está na lista de colunas editáveis
    if (!editableColumnIds.includes(currentColumnId)) {
      alert('Você não tem permissão para mover o card a partir desta coluna.');
      return;
    }

    // Verifica se a coluna de destino está na lista de colunas editáveis
    if (!editableColumnIds.includes(newColumnId)) {
      alert('Você não tem permissão para mover o card para esta coluna.');
      return;
    }

    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }

    try {
      setLoadingModal(true);
      setLoadingResult('Alterando Coluna...');
      // Chama a API para atualizar o column_id no banco de dados
      const response = await axios.post(`${apiUrl}/card/update-column`, {
        cardId: currentCardId,
        columnId: newColumnId
      });

      if (response.data) {
        addHistoricoCardContext(`Coluna alterada para ${getNameColumnCard(newColumnId)}`, currentCardId, user.id);
      } else {
        throw new Error('No data returned');
      }

      // Atualização otimista: Atualiza o estado imediatamente
      const newCards = cards.map(card => {
        if (card.card_id === currentCardId) {
          return { ...card, column_id: newColumnId }; // Assume que a propriedade que define a coluna é `column_id`
        }
        return card;
      });
      setCards(newCards);

      setLoadingModal(false);
      setLoadingResult('');
    } catch (error) {
      setLoadingResult('Erro ao alterar Card de Coluna!');
      console.error('Failed to update card column:', error);
      // Reverte a mudança otimista em caso de erro
      setCards(startCards);
      alert('Failed to move card, please try again.');
    }
  };









  useEffect(() => {
    //setPreviewSearchCards([])
  }, [searchTerm]);

  function clearSearchTerm() {
    setSearchTerm('');
    setPreviewSearchCards([]);
    setOpenCloseSearchModal(!openCloseSearchModal)
  }

  // const handleSelectChange = (id) => {
  //   setSelectedAfilhados(prevSelected =>
  //     prevSelected.includes(id)
  //       ? prevSelected.filter(afilhadoId => afilhadoId !== id)
  //       : [...prevSelected, id]
  //   );
  // };

  const handleSelectChange = (id) => {
    setSelectedAfilhados(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(afilhadoId => afilhadoId !== id)
        : [...prevSelected, id]
    );
  };




  // const handleSelectAll = () => {
  //   if (selectedAfilhados.length === afilhadosList.length + 1) {
  //     setSelectedAfilhados([]);
  //   } else {
  //     setSelectedAfilhados([user.id, ...afilhadosList.map(afilhado => afilhado.id)]);
  //   }
  // };

  // const handleSelectAll = () => {
  //   if (selectedAfilhados.length === afilhadosList.length + 1 && showSharedCards) {
  //     setSelectedAfilhados([]);
  //     setShowSharedCards(false);  // Desativa a visualização dos compartilhados ao desmarcar todos
  //   } else {
  //     setSelectedAfilhados([user.id, ...afilhadosList.map(afilhado => afilhado.id)]);
  //     setShowSharedCards(true);   // Ativa a visualização dos compartilhados ao selecionar todos
  //   }
  // };

  const handleSelectAll = () => {


    if (selectedAfilhados.length === afilhadosList.length + 1) {
      setSelectedAfilhados([]); // Desmarcar todos
      setShowSharedCards(false);

    } else {
      setSelectedAfilhados([user.id, ...afilhadosList.map(afilhado => afilhado.id)]); // Selecionar todos
      setShowSharedCards(true);

    }
  };



  useEffect(() => {
    if (user && !selectedAfilhados.includes(user.id)) {
      setSelectedAfilhados([user.id]);
    }
  }, [user]);



  const handleTempDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "tempDataInicial") {
      setDataInicial(value);
    } else if (name === "tempDataFinal") {
      setDataFinal(value);
    }
  };


  // const handleUpdateDates = () => {
  //   fetchCards(dataInicial, dataFinal)
  // };

  const [dataType, setDataType] = useState('created_at'); // Valor padrão é 'create_at'


  const handleUpdateDates = () => {
    fetchCards(dataInicial, dataFinal, dataType); // Passe o dataType como parâmetro
  };




  const exportarTabelasExcel = async () => {

    const userConfirmed = window.confirm(`Exportar Planilhas?`);
    if (!userConfirmed) {
      return;
    }

    const tables = ['cards', 'users', 'modulo_esquadrias', 'history', 'tasks'];

    for (const table of tables) {
      try {
        const response = await axios.post(`${apiUrl}/users/${table}`, {
          empresa_id: user.empresa_id,
        });
        const data = response.data;

        if (data.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(table);

          worksheet.columns = Object.keys(data[0]).map((key) => ({
            header: key,
            key: key,
            width: 20,
          }));

          data.forEach((row) => {
            worksheet.addRow(row);
          });

          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${table}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.error(`Erro ao exportar tabela ${table}:`, error);
      }
    }
  };



  function reloadPage() {
    window.location.reload();
  }



  const [localSearchInput, setLocalSearchInput] = useState('');

  // Função para atualizar o termo de busca local
  const onLocalSearch = () => {
    handleLocalSearch(localSearchInput);
  };


  const [isLocalSearchVisible, setIsLocalSearchVisible] = useState(true);




  const [afilhadoSearchTerm, setAfilhadoSearchTerm] = useState('');

  const handleAfilhadoSearch = (searchTerm) => {
    setAfilhadoSearchTerm(searchTerm);
  };

  const filteredAfilhadosList = afilhadosList.filter(afilhado =>
    afilhado.username.toLowerCase().includes(afilhadoSearchTerm.toLowerCase())
  );






  const handleSelectAllSetores = () => {
    setSelectedSetores(setores);
  };

  const handleDeselectAllSetores = () => {
    setSelectedSetores([]);
  };



  const selectAllAfilhados = () => {
    setSelectedAfilhados([user.id, ...afilhadosList.map(afilhado => afilhado.id)]);
  };


  useEffect(() => {
    if (user && afilhadosList.length > 0) {
      selectAllAfilhados();
    }
  }, [user, afilhadosList]);









  //-----------------------------------------------
  //-----------------------------------------------
  //-----------------------------------------------
  //----------------- ENVIAR EMAIL ----------------
  //-----------------------------------------------
  //-----------------------------------------------
  //-----------------------------------------------

  const handleSendEmail = async () => {


    // Dados do e-mail
    const emailData = {
      to: empresa.email_solicitacao_pedidos, // Alterar para o e-mail do destinatário
      subject: 'Novo Pedido Criado',
      text: 'Um novo pedido foi criado.',
    };

    try {
      const response = await axios.post(`${apiUrl}/users/send-email/${empresa.id}`, emailData);

    } catch (err) {

    } finally {

    }
  };













  const [showPotentialSales, setShowPotentialSales] = useState(false);
  const [potentialSalesData, setPotentialSalesData] = useState([]);

  const fetchPotentialSalesData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/users/potential-sales`, {
        params: {
          userId: user.id,
          empresaId: user.empresa_id,
        }
      });


      // Verifica se a resposta é um array
      if (Array.isArray(response.data)) {
        setPotentialSalesData(response.data);
      } else {
        // Se a resposta não for um array, define um array vazio ou lida de outra forma
        setPotentialSalesData([]);
        console.error('Resposta inesperada do servidor:', response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar dados de vendas potenciais:', error);
      setPotentialSalesData([]); // Em caso de erro, define um array vazio para evitar o erro de map
    }
  };






  const [isSearchLocalVisible, setIsSearchLocalVisible] = useState(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.search-card-input-local')) {
        setIsSearchLocalVisible(false); // Esconde a div ao clicar fora do input
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const [showGoogleCalendar, setShowGoogleCalendar] = useState(false);



  return (
    <div className='home-page-container'>



      <Header />
      <Vendas />
      <BemVindo />
      <div className='tools-container'>









        <div>
          <button style={{ display: 'none' }} onClick={handleSendEmail} >
            Send Email
          </button>
        </div>


        {/* <MdOutlineSearch onClick={() => clearSearchTerm()} style={{ display: openCloseFilterModal || openCloseSelectAfilhadosModal || openCloseSelectDateModal ? 'none' : '', background: openCloseSearchModal ? 'dodgerblue' : '', cursor: 'pointer' }} className='search-icon-open-close' /> */}
        {openCloseSearchModal ? (
          <span
            onClick={() => clearSearchTerm()}
            className='search-icon-open-close'
            style={{
              backgroundColor: 'red',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            X
          </span>
        ) : (
          <MdOutlineSearch
            onClick={() => clearSearchTerm()}
            style={{
              display: openCloseFilterModal || openCloseSelectAfilhadosModal || openCloseSelectDateModal ? 'none' : '',
              background: openCloseSearchModal ? 'dodgerblue' : '',
              cursor: 'pointer'
            }}
            className='search-icon-open-close'
          />
        )}


        {openCloseSearchModal &&

          <div className='modal-date-filter-container'>





            <div className='select-search'>


              <div className='serach-imput-container'>
                <input
                  style={{ backgroundColor: searchTerm.trim() ? '#e0e0e0' : '', color: searchTerm.trim() ? 'rgb(83, 83, 83)' : '' }}
                  className='search-card-input'
                  placeholder="Buscar Cards..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation(); // Previne a propagação do clique
                  }}
                />
                <MdOutlineSearch style={{ cursor: 'pointer' }} className='search-icon' onClick={(e) => {
                  e.stopPropagation(); handleSearchClick()
                }} />

              </div>


              <div className='search-type-container'>
                <label className='label-search-radio'>
                  <input
                    type="radio"
                    value="name"
                    checked={searchType === 'name'}
                    onChange={() => setSearchType('name')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Nome
                </label>

                <label className='label-search-radio'>
                  <input
                    type="radio"
                    value="nome_obra"
                    checked={searchType === 'nome_obra'}
                    onChange={() => setSearchType('nome_obra')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Nome da Obra
                </label>

                <label style={{ display: 'none' }} className='label-search-radio'>
                  <input
                    style={{ display: 'none' }}
                    type="radio"
                    value="state"
                    checked={searchType === 'state'}
                    onChange={() => setSearchType('state')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Estado
                </label>
                <label className='label-search-radio'>
                  <input
                    type="radio"
                    value="document_number"
                    checked={searchType === 'document_number'}
                    onChange={() => setSearchType('document_number')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Número do Documento
                </label>
                <label style={{ display: 'none' }} className='label-search-radio'>
                  <input
                    type="radio"
                    value="origem"
                    checked={searchType === 'origem'}
                    onChange={() => setSearchType('origem')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Origem
                </label>
                <label style={{ display: 'none' }} className='label-search-radio'>
                  <input
                    type="radio"
                    value="produto"
                    checked={searchType === 'produto'}
                    onChange={() => setSearchType('produto')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Produto
                </label>
                <label className='label-search-radio'>
                  <input
                    type="radio"
                    value="pedido_number"
                    checked={searchType === 'pedido_number'}
                    onChange={() => setSearchType('pedido_number')}
                    className='input-radio-container'
                    onClick={(e) => {
                      e.stopPropagation(); // Previne a propagação do clique
                    }}
                  />
                  Número do Pedido
                </label>
              </div>
            </div>
          </div>
        }






        {/* <MdGroups style={{ display: openCloseFilterModal || openCloseSearchModal || openCloseSelectDateModal ? 'none' : '', background: openCloseSelectAfilhadosModal ? 'dodgerblue' : '' }} onClick={() => setOpenCloseSelectAfilhadosModal(!openCloseSelectAfilhadosModal)} className='afilhados-icon-open-close' /> */}



        {openCloseSelectAfilhadosModal ? (
          <span
            onClick={() => setOpenCloseSelectAfilhadosModal(!openCloseSelectAfilhadosModal)}
            className='afilhados-icon-open-close'
            style={{
              backgroundColor: 'red',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            X
          </span>
        ) : (
          <MdGroups
            style={{
              display: openCloseFilterModal || openCloseSearchModal || openCloseSelectDateModal ? 'none' : '',
              background: openCloseSelectAfilhadosModal ? 'dodgerblue' : ''
            }}
            onClick={() => setOpenCloseSelectAfilhadosModal(!openCloseSelectAfilhadosModal)}
            className='afilhados-icon-open-close'
          />
        )}










        {openCloseSelectAfilhadosModal && (
          <div className='modal-date-filter-container'>
            <div id="afilhadosSelect" className="select-filter">
              <label className="title-label-afilhados">Afilhados</label>
              <input
                className='input-search-afilhado-filter-afilhados'
                type="text"
                placeholder="Buscar Afilhado..."
                value={afilhadoSearchTerm}
                onChange={(e) => handleAfilhadoSearch(e.target.value)}
              />









              <div className="list-afilhados-container">
                <div
                  key="select-all"
                  className={`select-filter-option ${selectedAfilhados.length === afilhadosList.length + 1 ? 'selected' : ''}`}
                  style={{ backgroundColor: selectedAfilhados.length === afilhadosList.length + 1 ? '' : '' }}
                  onClick={handleSelectAll}
                >
                  {selectedAfilhados.length === afilhadosList.length + 1 ? 'Desselecionar Todos' : 'Selecionar Todos'}
                </div>

                <div
                  className={`select-filter-option ${showSharedCards ? 'selected' : ''}`}
                  onClick={() => setShowSharedCards(!showSharedCards)} // Alterna manualmente
                  style={{ backgroundColor: showSharedCards ? '#49b6f5' : '' }}
                >
                  {showSharedCards ? 'COMPARTILHADOS' : 'COMPARTILHADOS'}
                </div>

                <div
                  key={user.id}
                  className={`select-filter-option ${selectedAfilhados.includes(user.id) ? 'selected' : ''}`}
                  style={{ backgroundColor: selectedAfilhados.includes(user.id) ? '#49b6f5' : '' }}
                  onClick={() => handleSelectChange(user.id)}
                >
                  <img
                    className='logo-afilhado-lista'
                    src={user.avatar ? (userAvatar?.includes('syncs-avatar') ? require(`../../../assets/avatares/${userAvatar}`) : user.avatar) : logoDefault}
                  />
                  <label className='label-afilhados-lista'>
                    {user.username}
                    <label className='label-user-type' style={{ background: user.user_type == 'Administrador' ? 'red' : user.user_type == 'Supervisor' ? '#49c5ff' : '' }}>{user.user_type}</label>
                  </label>
                </div>

                {Object.entries(
                  filteredAfilhadosList.reduce((acc, afilhado) => {
                    const state = afilhado.state || 'Sem Estado'; // Adicione um fallback caso não haja estado
                    if (!acc[state]) {
                      acc[state] = [];
                    }
                    acc[state].push(afilhado);
                    return acc;
                  }, {})
                ).map(([state, afilhados]) => (
                  <React.Fragment key={state}>
                    <div className='state-divider'>{state}</div>
                    {afilhados.sort((a, b) => a.username.localeCompare(b.username)).map(afilhado => (
                      <div
                        key={afilhado.id}
                        className={`select-filter-option ${selectedAfilhados.includes(afilhado.id) ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedAfilhados.includes(afilhado.id) ? '#49b6f5' : '' }}
                        onClick={() => handleSelectChange(afilhado.id)}
                      >
                        <img
                          className='logo-afilhado-lista'
                          src={afilhado.avatar ? (afilhado.avatar.includes('syncs-avatar') ? require(`../../../assets/avatares/${afilhado.avatar}`) : afilhado.avatar) : logoDefault}
                        />
                        <label className='label-afilhados-lista'>
                          {afilhado.username}

                          <label
                            className='label-user-type'
                            style={{
                              background: afilhado.user_type === 'Administrador' ? 'red' : afilhado.user_type === 'Supervisor' ? '#f54966' : ''
                            }}
                          >
                            {afilhado.user_type}
                          </label>
                        </label>

                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}




























        {/* <BsCalendarDate style={{ display: openCloseSearchModal || openCloseFilterModal || openCloseSelectAfilhadosModal ? 'none' : '', background: openCloseSelectDateModal ? 'red' : '' }} onClick={() => setOpenCloseSelectDateModal(!openCloseSelectDateModal)} className='afilhados-icon-open-close' /> */}

        {openCloseSelectDateModal ? (
          <span
            onClick={() => setOpenCloseSelectDateModal(!openCloseSelectDateModal)}
            className='afilhados-icon-open-close'
            style={{

              backgroundColor: 'red',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            X
          </span>
        ) : (
          <BsCalendarDate
            style={{
              display: openCloseSearchModal || openCloseFilterModal || openCloseSelectAfilhadosModal ? 'none' : '',
              background: openCloseSelectDateModal ? 'red' : ''
            }}
            onClick={() => setOpenCloseSelectDateModal(!openCloseSelectDateModal)}
            className='afilhados-icon-open-close'
          />
        )}


        {openCloseSelectDateModal && (
          <div className='modal-date-filter-container'>
            <div className='date-filter-container'>
              <div className='date-filter-row'>
                <div className='date-filter-column'>
                  <label htmlFor="tempDataInicial" className='date-filter-label'>Data Inicial</label>
                  <input
                    className='date-filter-date'
                    type="date"
                    name="tempDataInicial"
                    value={dataInicial}
                    onChange={handleTempDateChange}
                  />
                </div>


                <div className='date-filter-column'>
                  <label htmlFor="tempDataFinal" className='date-filter-label'>Data Final</label>
                  <input
                    className='date-filter-date'
                    type="date"
                    name="tempDataFinal"
                    value={dataFinal}
                    onChange={handleTempDateChange}
                  />
                </div>
              </div>

              <div className='date-filter-column'>
                <label htmlFor="dataType" className='date-filter-label'>Tipo de Data</label>
                <select
                  id="dataType"
                  value={dataType}
                  onChange={(e) => setDataType(e.target.value)}
                  className='select-etiquetas-filter'
                >
                  <option value="created_at">Data de Criação</option>
                  <option value="updated_at">Data de Atualização</option>
                </select>
              </div>


              <button className='btn-update-date-filter' onClick={handleUpdateDates}>Atualizar Datas</button>
            </div>
          </div>
        )}




        {/* <MdOutlineFilterList style={{ display: openCloseSelectAfilhadosModal || openCloseSelectDateModal || openCloseSearchModal ? 'none' : '', background: openCloseFilterModal ? 'dodgerblue' : '' }} onClick={() => setOpenCloseFilterModal(!openCloseFilterModal)} className='afilhados-icon-open-close' /> */}

        {openCloseFilterModal ? (
          <span
            onClick={() => setOpenCloseFilterModal(!openCloseFilterModal)}
            className='afilhados-icon-open-close'
            style={{
              backgroundColor: 'red',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            X
          </span>
        ) : (
          <MdOutlineFilterList
            style={{
              display: openCloseSelectAfilhadosModal || openCloseSelectDateModal || openCloseSearchModal ? 'none' : '',
              background: openCloseFilterModal ? 'dodgerblue' : ''
            }}
            onClick={() => setOpenCloseFilterModal(!openCloseFilterModal)}
            className='afilhados-icon-open-close'
          />
        )}


        {openCloseFilterModal && (

          <div className='modal-date-filter-container'>
            <div className='filter-filter-container'>

              <div className='date-filter-row'>

                <div className='date-filter-column'>

                  <label htmlFor="tempDataInicial" className='date-filter-label'>Ordenar</label>

                  <div className='order-by-order'>
                    <button style={{ backgroundColor: isAscending ? 'dodgerblue' : '' }} className='btn-filter-tools-order' onClick={() => setIsAscending(!isAscending)}>
                      Crescente
                    </button>

                    <button style={{ backgroundColor: !isAscending ? 'dodgerblue' : '' }} className='btn-filter-tools-order' onClick={() => setIsAscending(!isAscending)}>
                      Decrescente
                    </button>
                  </div>



                  <button style={{ backgroundColor: orderBy === 'nome' ? 'dodgerblue' : '' }} className='btn-filter-tools' onClick={() => setOrderBy('nome')}>Nome</button>
                  <button style={{ backgroundColor: orderBy === 'dataStatus' ? 'dodgerblue' : '' }} className='btn-filter-tools' onClick={() => setOrderBy('dataStatus')}>Data de status</button>
                  <button style={{ backgroundColor: orderBy === 'dataCreate' ? 'dodgerblue' : '' }} className='btn-filter-tools' onClick={() => setOrderBy('dataCreate')}>Data de criação</button>
                  <button style={{ backgroundColor: orderBy === 'dataUpdate' ? 'dodgerblue' : '' }} className='btn-filter-tools' onClick={() => setOrderBy('dataUpdate')}>Data de atualização</button>
                  <button style={{ backgroundColor: orderBy === 'value' ? 'dodgerblue' : '' }} className='btn-filter-tools' onClick={() => setOrderBy('value')}>Valor</button>


                </div>

              </div>






              <label htmlFor="etiquetas" className='date-filter-label'>Etiquetas</label>

              <div className='filter-select-columns'>
                <select
                  id="etiquetas"
                  value={selectedEtiqueta}
                  onChange={(e) => setSelectedEtiqueta(e.target.value)}
                  className='select-etiquetas-filter'
                >
                  <option value="">Todas as Etiquetas</option> {/* Opção para remover o filtro */}
                  {listaEtiquetas.map(etiqueta => (
                    <option key={etiqueta.id} value={etiqueta.id}>
                      {etiqueta.description}
                    </option>
                  ))}
                </select>
              </div>

























              <label htmlFor="tempDataInicial" className='date-filter-label'>Setores</label>


              <div className='filter-select-columns'>

                <button
                  className='btns-filter-select-columns'
                  onClick={handleSelectAllSetores}

                >
                  Selecionar Todos
                </button>
                <button
                  className='btns-filter-select-columns'
                  onClick={handleDeselectAllSetores}

                >
                  Deselecionar Todos
                </button>


                {setores.map((setor, index) => (
                  <button
                    className='btns-filter-select-columns'

                    key={index}
                    style={{
                      backgroundColor: selectedSetores.includes(setor) ? 'dodgerblue' : '#f0f0f0',
                      color: selectedSetores.includes(setor) ? 'white' : '#333',
                      transition: 'background-color 0.3s, color 0.3s',
                    }}
                    onClick={() => handleSetorChange(setor)}
                  >
                    {setor}
                  </button>
                ))}
              </div>

            </div>



          </div>
        )}





        {user && getAccessLevel('exportExcel') &&
          <RiFileExcel2Line style={{ display: openCloseFilterModal || openCloseSelectAfilhadosModal || openCloseSelectDateModal || openCloseSearchModal ? 'none' : '' }} onClick={() => exportarTabelasExcel()} className='afilhados-icon-open-close' />
        }


        <MdOutlineUpdate style={{ display: openCloseFilterModal || openCloseSelectAfilhadosModal || openCloseSelectDateModal || openCloseSearchModal ? 'none' : '' }} onClick={() => reloadPage()} className='afilhados-icon-open-close' />


        {isLocalSearchVisible && (
          <div className='buscar-localmente-container'>
            <input
              className='search-card-input-local'
              style={{ marginLeft: '4px' }}
              type="text"
              value={localSearchInput}
              onChange={(e) => {
                setLocalSearchInput(e.target.value);
                handleLocalSearch(e.target.value);
              }}
              onClick={() => setIsSearchLocalVisible(true)} // Mostra a div ao clicar
              placeholder="Busca Local..."
            />

            <MdOutlineSearch className='search-icon' />
          </div>
        )}


        {isSearchLocalVisible && (
          <div className='tipo-de-busca-local-container'>
            <p className='search-title'>Buscar por:</p>
            <ul className='search-options-list'>
              <li><MdPerson className='search-icon-info' /> Nome do cliente</li>
              <li><MdPhone className='search-icon-info' /> Telefone</li>
              <li><MdAttachMoney className='search-icon-info' /> Custo</li>
              <li><MdDescription className='search-icon-info' /> Número do orçamento</li>
              <li><MdLocalShipping className='search-icon-info' /> Número do Pedido</li>
              <li><MdLocationCity className='search-icon-info' /> Cidade</li>
              <li><MdLabel className='search-icon-info' /> @ ID da Coluna</li>
              <li><MdPublic className='search-icon-info' /> # Origem</li>
              <li><MdPerson className='search-icon-info' /> Responsável</li> {/* Nova opção de busca */}
            </ul>
          </div>
        )}





        <BsCalendarDate style={{ display: 'none' }} onClick={() => setShowGoogleCalendar(!showGoogleCalendar)} className='afilhados-icon-open-close' />

        {showGoogleCalendar && (
          <GoogleCalendario />

        )}








      </div>





      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className='home-container'>
          {columnsUser ? (
            columnsUser
              .filter(column => selectedSetores.includes(column.setor))
              .map(column => (
                <Column key={column.id} columnData={column} />
              ))
          ) : (
            <>sem colunas</>
          )}
        </div>
      </DragDropContext>


      {(previewSearchCards.length > 0 || loadingSearch) && (
        <div className='search-result-container'>
          {loadingSearch && (
            <div className='loading-container-search'>
              Loading...
            </div>
          )}

          {previewSearchCards.map((item) => (
            <div className='item-search-result-card' key={item.card_id} onClick={() => getCardData(item)}>
              <PreviewCard key={item.card_id} cardData={item} />
            </div>
          ))}
        </div>
      )}

      {openCloseImportExcelEntidades && <ImportExcel />}
      {openCloseImportExcelSuiteFlow && <ImportExcelSuiteFlow />}



    </div>
  );
}

export default HomePage;
